import { isPlatformBrowser, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { PLATFORM_ID, Injectable, Inject, InjectionToken, Optional, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { merge, cloneDeep, has, set, get } from '@cocha/ngx-codex/src/lib/utils';
import { map, catchError } from 'rxjs/operators';
import * as i1 from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import * as i3 from '@angular/router';
class CookieService {
  constructor(platformId) {
    this.platformId = platformId;
    this.isConsented = false;
  }
  get(name) {
    const cookies = isPlatformBrowser(this.platformId) ? document.cookie.split(';') : [];
    const cookiesLen = cookies.length;
    const cookieName = `${name}=`;
    let c;
    for (let i = 0; i < cookiesLen; i += 1) {
      c = cookies[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
  deleteCookie(name, path, domain) {
    this.setCookie(name, '', -1, path, domain);
  }
  setCookie(name, value, expireDays = 14, path = '/', domain) {
    if (isPlatformBrowser(this.platformId)) {
      const d = new Date();
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
      const expires = `expires=${d.toUTCString()}`;
      const cpath = path ? `; path=${path}` : '';
      const cdomain = domain ? `; domain=${domain}` : '';
      document.cookie = `${name}=${value}; ${expires}${cpath}${cdomain}`;
    }
  }
  check(name) {
    const cookie = this.get(name);
    if (!cookie || cookie !== '') {
      return false;
    } else {
      return true;
    }
  }
  static {
    this.ɵfac = function CookieService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CookieService)(i0.ɵɵinject(PLATFORM_ID));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CookieService,
      factory: CookieService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CookieService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], null);
})();
class LocalService {
  constructor(platformId, cookieService) {
    this.platformId = platformId;
    this.cookieService = cookieService;
    this.duration = 14;
    this.storageSupport = true;
    this.cookieName = 'Storage.' + window['APP_NAME'];
    try {
      sessionStorage.setItem('test', 'Navegacion incógnito en Safari no soporta sessionStorage');
      sessionStorage.removeItem('test');
      localStorage.setItem('test', 'Navegacion incógnito en Safari no soporta localStorage');
      localStorage.removeItem('test');
    } catch (e) {
      console.log('[cAccessLocal]', 'storage not supported', e);
      this.storageSupport = false;
    }
  }
  initializeLocalStorage(_key, value) {
    console.log('[cAccessLocal]', '[Init]', 'Initializing local storage: key=' + _key);
    this.getStorageData(_key).subscribe(_storageData => this.setStorageData(merge(value, cloneDeep(_storageData)), _key).subscribe(response => {}, err => {
      console.log('err', err);
      this.setStorageData(value, _key).subscribe(response => {}, err2 => console.log('err2', err2));
    }), err => {
      console.log('[cAccessLocal]', '[error]', err);
      this.setStorageData(value, _key).subscribe(response => {}, err3 => console.log('err3', err3));
    }, () => console.log('[cAccessLocal]', '[End]', 'Initialized local storage: key=' + _key));
  }
  initializeSessionStorage(key, value) {
    console.log('[cAccessLocal]', 'Initializing session storage: key=' + key);
    try {
      this.setSessionData(merge(value, this.getSessionData(key)), key).subscribe(_data1 => {}, err3 => console.log('err3', err3));
    } catch (e) {
      this.setSessionData(value, key).subscribe(_data1 => {}, err3 => console.log('err3', err3));
    }
  }
  /*
   * Data setters and getters.
   * After value comes a variable number of arguments that are the internal route of the storage.
   */
  setSessionData(_value, _key) {
    const route = _key;
    console.log('[cAccessLocal]', 'setSessionData: route= ' + route.join('->') + ' <-- value=', _value);
    return this.setData('session', route, _value);
  }
  getSessionData(_key) {
    const route = _key;
    console.log('[cAccessLocal]', 'getSessionData: route= ' + route.join('->'));
    return this.getData('session', route);
  }
  getSessionReference(_key) {
    const route = _key;
    console.log('[cAccessLocal]', 'getSessionReference: route= ' + route.join('->'));
    return this.getData('session', route);
  }
  setStorageData(_value, _key) {
    const route = _key;
    console.log('[cAccessLocal]', 'setStorageData: route= ' + route.join('->') + ' <-- value=', _value);
    return this.setData('local', route, _value);
  }
  getStorageData(_key) {
    const route = _key;
    console.log('[cAccessLocal]', 'getStorageData: route= ' + route.join('->'));
    return this.getData('local', route);
  }
  getStorageReference(_key) {
    const route = _key;
    console.log('[cAccessLocal]', 'getStorageReference: route= ' + route.join('->'));
    return this.getData('local', route);
  }
  deleteStorageData(_key) {
    const route = _key;
    console.log('[cAccessLocal]', 'deleteStorageData: route= ' + route.join('->'));
    this.deleteData('local', route);
  }
  deleteSessionData(_key) {
    const route = _key;
    console.log('[cAccessLocal]', 'deleteSessionData: route= ' + route.join('->'));
    this.deleteData('session', route);
  }
  hasItem(pointer, key) {
    const [first] = key;
    const stored = this.getStorageObject(pointer).getItem(first);
    if (!stored) {
      return false;
    } else {
      if (key.length > 1) {
        const [, ...tail] = key;
        return has(JSON.parse(stored), tail.join('.'));
      } else {
        return true;
      }
    }
  }
  allStorage(pointer) {
    const values = {};
    const keys = Object.keys(pointer);
    let i = keys.length;
    while (i--) {
      const item = pointer.getItem(keys[i]);
      if (item && item?.length > 0) {
        values[keys[i]] = JSON.parse(item);
      }
    }
    return values;
  }
  routeToString(route) {
    let out = '';
    if (route.length > 1) {
      for (let i = 0; i < route.length - 1; i++) {
        out += route[i] + '-->';
      }
      out += route[route.length - 1];
    } else {
      out = route.toString();
    }
    return out;
  }
  setData(storage, key, value) {
    const [first] = key;
    const stored = this.getStorageObject(storage).getItem(first);
    // eslint-disable-next-line prefer-const
    let pointer = {};
    if (stored && stored?.length > 0) {
      pointer = JSON.parse(stored) || {};
    }
    if (key.length > 1) {
      const [, ...tail] = key;
      set(pointer, tail.join('.'), value);
    } else {
      pointer = value;
    }
    console.log('[cAccessLocal]', '\tonKey: ', first, '\tsetData: ', pointer);
    return this.setStorageObject(storage, first, value);
  }
  getData(_storage, route) {
    return new Observable(obs => {
      const [first] = route;
      const itemStored = this.getStorageObject(_storage).getItem(first); // ES NULL SI NO EXISTE
      if (!!itemStored && this.hasItem(_storage, route)) {
        const stored = JSON.parse(itemStored);
        if (route.length > 1) {
          const [, ...tail] = route;
          obs.next(get(stored, tail.join('.')));
        } else {
          obs.next(stored);
        }
        obs.complete();
      } else {
        obs.error(new Error('StorageError: Could not access to route: ' + route.join('->') + '. Variable was not set in storage'));
      }
    });
  }
  deleteData(_storage, route) {
    console.log('[cAccessLocal]', '\tdeleteData: ' + route);
    return this.setData(_storage, route, null);
  }
  getStorageObject(_type) {
    if (this.storageSupport) {
      return _type === 'local' ? localStorage : sessionStorage;
    } else {
      return this.readCookies(_type);
    }
  }
  getStorageObjectValue(_type, _key) {
    if (this.storageSupport) {
      return _type === 'local' ? localStorage.getItem(_key) : sessionStorage.getItem(_key);
    } else {
      return this.readCookies(_type);
    }
  }
  setStorageObject(_type, _key, value) {
    return new Observable(obs => {
      if (this.storageSupport) {
        if (_type === 'local') {
          localStorage.setItem(_key, JSON.stringify(value));
          obs.next(true);
          obs.complete();
        } else {
          sessionStorage.setItem(_key, JSON.stringify(value));
          obs.next(true);
          obs.complete();
        }
      } else {
        this.writeCookies(_type, JSON.stringify(value)).subscribe(() => {
          console.log('complete writeCookies');
          obs.next(true);
          obs.complete();
        }, err => obs.error(err));
      }
    });
  }
  readCookies(_type) {
    let i = 0;
    let data = '';
    let block = this.getCookie(_type + this.cookieName);
    while (block) {
      data += block;
      i++;
      block = this.getCookie(_type + this.cookieName + i);
    }
    try {
      return JSON.parse(decodeURIComponent(data)) || {};
    } catch (e) {
      return {};
    }
  }
  writeCookies(_type, value) {
    return new Observable(obs => {
      value = this.readCookies(_type);
      const cookieSize = 4000;
      const data = this.cookieEncode(value);
      if (data.length > 20 * cookieSize) {
        obs.error(new Error('StorageError: content to long to save in cookies'));
      } else {
        for (let i = 0; i < 20; i++) {
          if (i * cookieSize <= data.length) {
            this.cookieService.setCookie(_type + this.cookieName + (i || ''), data.substr(i * cookieSize, cookieSize), this.duration, '/');
          } else {
            this.cookieService.deleteCookie(_type + this.cookieName + (i || ''));
          }
        }
        obs.next(true);
        obs.complete();
      }
    });
  }
  getCookie(name) {
    const cookie = this.cookieService.get(name);
    if (!!cookie) {
      return cookie;
    } else {
      return null;
    }
  }
  cookieEncode(value) {
    return encodeURIComponent(JSON.stringify(value)).replace(/%7B/g, '{').replace(/%7D/g, '}').replace(/%22/g, '"').replace(/%5C/g, '\\').replace(/%3A/g, ':').replace(/%2C/g, ',');
  }
  static {
    this.ɵfac = function LocalService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LocalService)(i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(CookieService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LocalService,
      factory: LocalService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: CookieService
  }], null);
})();
const SID_VALIDATE = 'https://mid.cocha.com/hotels/v1/session/:sid';
const USER_LOCALSTORAGE_NAME = 'cochauser';
const SID_COOKIE_NAME = new InjectionToken('cocha_cross_p');
const APP_NAME = new InjectionToken('');
const WEB_MONITOR_URL = new InjectionToken('');
const LOGGER_URL = new InjectionToken('');
class HttpService {
  constructor(http, cookieService, router, cookieName, appName, loggerUrl, webMonitorUrl, platformId) {
    this.http = http;
    this.cookieService = cookieService;
    this.router = router;
    this.cookieName = cookieName;
    this.appName = appName;
    this.loggerUrl = loggerUrl;
    this.webMonitorUrl = webMonitorUrl;
    this.platformId = platformId;
    this.flowId = '';
    // TODO: replace some day with crypto.randomUUID();
    this.S4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    this.generateUUID = () => `${this.S4()}${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}${this.S4()}${this.S4()}`;
    this.loadSID();
    this.loadSocialUser();
    this.loadFlowId();
  }
  get(_url, _headers = {}, _step = '', _vertical, _skipAuth = false) {
    const headers = this.setSessionHeaders(_headers, _url, _skipAuth);
    const initTime = new Date();
    return this.http.get(_url, {
      headers,
      observe: 'response'
    }).pipe(this.responseHandler('GET', _url, null, _headers, _step, initTime, _vertical));
  }
  post(_url, _data, _headers = {}, _step = '', _vertical, _skipAuth = false) {
    const headers = this.setSessionHeaders(_headers, _url, _skipAuth);
    const initTime = new Date();
    return this.http.post(_url, _data, {
      headers,
      observe: 'response'
    }).pipe(this.responseHandler('POST', _url, _data, _headers, _step, initTime, _vertical));
  }
  patch(_url, _data, _headers = {}, _step = '', _vertical, _skipAuth = false) {
    const headers = this.setSessionHeaders(_headers, _url, _skipAuth);
    const initTime = new Date();
    return this.http.patch(_url, _data, {
      headers,
      observe: 'response'
    }).pipe(this.responseHandler('PATCH', _url, _data, _headers, _step, initTime, _vertical));
  }
  put(_url, _data, _headers = {}, _step = '', _vertical, _skipAuth = false) {
    const headers = this.setSessionHeaders(_headers, _url, _skipAuth);
    const initTime = new Date();
    return this.http.put(_url, _data, {
      headers,
      observe: 'response'
    }).pipe(this.responseHandler('PUT', _url, _data, _headers, _step, initTime, _vertical));
  }
  delete(_url, _headers = {}, _step = '', _vertical, _skipAuth = false) {
    const headers = this.setSessionHeaders(_headers, _url, _skipAuth);
    const initTime = new Date();
    return this.http.delete(_url, {
      headers,
      observe: 'response'
    }).pipe(this.responseHandler('DELETE', _url, null, _headers, _step, initTime, _vertical));
  }
  responseHandler(requestMethod, url, requestBody, requestHeaders, step, initTime, vertical) {
    // only log with a step, appName and loggerUrl
    const logger = !step || !this.appName || !this.loggerUrl && !this.webMonitorUrl ? r => r : response => {
      try {
        const endTime = new Date();
        const responseHeaders = {};
        if (response?.headers) {
          const keys = response.headers.keys();
          keys.map(key => responseHeaders[key] = response.headers.get(key));
        }
        const dataToLog = {
          app: this.appName,
          location: this.router.url,
          requestMethod,
          requestUrl: url,
          requestBody,
          requestHeaders,
          response: JSON.stringify(response.body || response.error).substring(0, 2000),
          responseStatus: response?.status,
          responseHeaders,
          responseTime: endTime.getTime() - initTime.getTime(),
          step,
          vertical: vertical || this.vertical,
          flowId: this.flowId,
          trackId: requestHeaders.TrackId,
          ...(this.channel ? {
            channel: this.channel
          } : {})
        };
        this.http.post(this.loggerUrl || `${this.webMonitorUrl}/funnel`, dataToLog).subscribe(r => r);
      } catch (e) {
        console.error('problems logging the request', e);
      }
    };
    return function (source) {
      return source.pipe(map(response => {
        logger(response);
        return response.body;
      }), catchError(err => {
        logger(err);
        throw err;
      }));
    };
  }
  loadSID() {
    this.sid = this.cookieService.get(this.cookieName);
  }
  loadSocialUser() {
    const storedUser = isPlatformBrowser(this.platformId) ? localStorage.getItem(USER_LOCALSTORAGE_NAME) : '';
    if (storedUser) {
      this.user = JSON.parse(storedUser);
    }
  }
  setVertical(vertical) {
    this.vertical = vertical;
  }
  setChannel(channel) {
    this.channel = channel;
  }
  /**
   * Loads the flow ID from local storage and assigns it to the 'flowId' property.
   * If the flow ID is not found in local storage, it calls the 'resetFlowId' method to generate a new flow ID.
   *
   * @returns void
   */
  loadFlowId() {
    const flowId = isPlatformBrowser(this.platformId) ? localStorage.getItem('flowId') : '';
    if (flowId) {
      this.flowId = flowId;
    } else {
      this.resetFlowId();
    }
  }
  /**
   * Resets the flow ID by generating a new UUID and storing it in the local storage.
   * This method is used to reset the flow ID when needed.
   *
   * @returns void
   */
  resetFlowId() {
    this.flowId = this.generateUUID();
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('flowId', this.flowId);
    }
  }
  /**
   * Sets headers for Cocha APIs.
   *
   * @param _headers - The request headers.
   * @param _url - The URL string.
   * @returns The updated request headers object with session headers.
   */
  setSessionHeaders(_headers, _url, _skipAuth) {
    if (_url.indexOf('cocha.com') > -1 || _url.indexOf('cocha.cloud') > -1) {
      if (!_skipAuth) {
        if (this.sid && this.sid !== '' && !_headers.disableSID) {
          _headers.sid = this.sid;
        }
        if (this.user?.idToken) {
          _headers.authorization = this.user?.idToken;
        }
      }
      _headers.FlowId = this.flowId;
      _headers.TrackId = this.generateUUID();
    }
    return _headers;
  }
  static {
    this.ɵfac = function HttpService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HttpService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(CookieService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(SID_COOKIE_NAME, 8), i0.ɵɵinject(APP_NAME, 8), i0.ɵɵinject(LOGGER_URL, 8), i0.ɵɵinject(WEB_MONITOR_URL, 8), i0.ɵɵinject(PLATFORM_ID));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HttpService,
      factory: HttpService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: CookieService
  }, {
    type: i3.Router
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [SID_COOKIE_NAME]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [APP_NAME]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [LOGGER_URL]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [WEB_MONITOR_URL]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], null);
})();
const MAX_ERROR_REPORTS_COUNT = new InjectionToken('');
class GlobalErrorHandler {
  constructor(http, router, appName, injectedAppMaxReportsCount, webMonitorUrl, platformId) {
    this.http = http;
    this.router = router;
    this.appName = appName;
    this.injectedAppMaxReportsCount = injectedAppMaxReportsCount;
    this.webMonitorUrl = webMonitorUrl;
    this.platformId = platformId;
    this.reportsCount = 0;
    this.maxReportsCount = 5;
    if (injectedAppMaxReportsCount) {
      this.maxReportsCount = injectedAppMaxReportsCount;
    }
  }
  handleError(err = {}) {
    const {
      stack,
      message,
      error
    } = err;
    if (this.webMonitorUrl && this.appName && this.reportsCount < this.maxReportsCount && (stack || message || error)) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const {
            userAgent,
            platform,
            language,
            cookieEnabled
          } = navigator;
          this.http.post(`${this.webMonitorUrl}/error`, {
            app: this.appName,
            location: this.router.url,
            trace: JSON.stringify(stack || message || error),
            flowId: this.http.flowId,
            browserInfo: {
              userAgent,
              platform,
              language,
              cookieEnabled
            }
          }).subscribe(r => r);
        }
        this.reportsCount++;
      } catch (e) {
        console.error(e);
      }
    }
    console.error(err);
  }
  static {
    this.ɵfac = function GlobalErrorHandler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GlobalErrorHandler)(i0.ɵɵinject(HttpService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(APP_NAME, 8), i0.ɵɵinject(MAX_ERROR_REPORTS_COUNT, 8), i0.ɵɵinject(WEB_MONITOR_URL, 8), i0.ɵɵinject(PLATFORM_ID));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GlobalErrorHandler,
      factory: GlobalErrorHandler.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GlobalErrorHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: HttpService
  }, {
    type: i3.Router
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [APP_NAME]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [MAX_ERROR_REPORTS_COUNT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [WEB_MONITOR_URL]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], null);
})();
class FetchModule {
  static {
    this.ɵfac = function FetchModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FetchModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FetchModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [HttpService, CookieService, LocalService, GlobalErrorHandler],
      imports: [CommonModule, HttpClientModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FetchModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CommonModule, HttpClientModule],
      providers: [HttpService, CookieService, LocalService, GlobalErrorHandler]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { APP_NAME, CookieService, FetchModule, GlobalErrorHandler, HttpService, LOGGER_URL, LocalService, MAX_ERROR_REPORTS_COUNT, SID_COOKIE_NAME, WEB_MONITOR_URL };
