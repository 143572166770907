/**
 * Creates a deep copy of an object or array.
 *
 * @param obj The object or array to be cloned.
 * @returns The deep copy of the input object or array.
 *
 * @example
 * const travel = {
 *   destination: "Paris",
 *   duration: 7,
 *   details: {
 *     cost: 1500,
 *     activities: ["Sightseeing", "Cuisine", "Shopping"],
 *   },
 * };
 *
 * // Create a deep copy of the travel object
 * const clonedTravel = cloneDeep(travel);
 *
 * // Modify the cloned object
 * clonedTravel.destination = "London";
 * clonedTravel.details.cost = 1800;
 *
 * // The original travel object remains unchanged
 * console.log(travel.destination); // Output: "Paris"
 * console.log(travel.details.cost); // Output: 1500
 *
 * // The cloned travel object has the modified values
 * console.log(clonedTravel.destination); // Output: "London"
 * console.log(clonedTravel.details.cost); // Output: 1800
 */
const cloneDeep = obj => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }
  let clone;
  if (Array.isArray(obj)) {
    clone = obj.map(item => cloneDeep(item));
  } else {
    clone = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        clone[key] = cloneDeep(obj[key]);
      }
    }
  }
  return clone;
};

/**
* Sets the value of a property within an object given a path.
*
* @param obj The object in which to set the property value.
* @param path The path to the property within the object. It can be an array of strings or a dot-separated string.
* @param value The value to set for the property.
*
* @example
* const travel = {
*  destination: "Paris",
*  duration: 7,
*  details: {
*    cost: 1500,
*    activities: ["Sightseeing", "Cuisine", "Shopping"],
*    hotel: {
*       stars: 3,
*    },
*   },
* };
*
* // Set a new number of stars  for the hotel
* set(travel, "details.hotel.stars", 4);
* console.log(travel.destination.details.hotel.stars); // Output: 4
*/
const set = (obj, path, value) => {
  const pathArray = Array.isArray(path) ? path : path.split(".");
  let current = obj;
  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i];
    if (!current[key]) current[key] = {};
    current = current[key];
  }
  current[pathArray[pathArray.length - 1]] = value;
};

/**
 * Gets the value of a property within an object given a path.
 *
 * @param obj The object from which to retrieve the property value.
 * @param path The path to the property within the object. It can be an array of strings or a dot-separated string.
 * @param defaultValue The default value to return if the property is not found in the object. It is optional, and its type is automatically inferred if not provided.
 * @returns The property value if found in the object, otherwise, the default value.
 *
 * @example
 * const travel = {
 *   destination: "Paris",
 *   duration: 7,
 *   details: {
 *     cost: 1500,
 *     activities: ["Sightseeing", "Cuisine", "Shopping"],
 *     hotel: {
 *        stars: 5,
 *     }
 *   },
 * };
 * // Get stars of hotel. If not found, use 3 as the default value.
 * const stars = get(travel, "details.hotel.stars", 3);
 * console.log(stars); // Output: 5
 */
const get = (obj, path, defaultValue) => {
  const pathArray = Array.isArray(path) ? path : path.split(".");
  let result = obj;
  for (const key of pathArray) {
    if (result !== null && result !== undefined && Object.prototype.hasOwnProperty.call(result, key)) {
      result = result[key];
    } else {
      return defaultValue;
    }
  }
  return result;
};

/**
* Checks if a property exists within an object given a path.
*
* @param obj The object to check for the property existence.
* @param path The path to the property within the object. It can be an array of strings or a dot-separated string.
* @returns True if the property exists, otherwise false.
*
* @example
* const travel = {
*   destination: "Paris",
*   duration: 7,
*   details: {
*     cost: 1500,
*     activities: ["Sightseeing", "Cuisine", "Shopping"],
*     hotel: {
*        stars: 3,
*     },
*   },
* };
*
* // Check if the property "destination" exists in the travel object
* const hasDestination = has(travel, "destination");
* console.log(hasDestination); // Output: true
*
* // Check if the property "details.cost" exists in the travel object
* const hasCost = has(travel, "details.cost");
* console.log(hasCost); // Output: true
*
* // Check if the property "details.rating" exists in the travel object
* const hasRating = has(travel, "details.rating");
* console.log(hasRating); // Output: false
*/
const has = (obj = {}, path = '') => {
  const pathArray = Array.isArray(path) ? path : path.split(".");
  let result = obj;
  for (const key of pathArray) {
    if (result !== null && result !== undefined && Object.prototype.hasOwnProperty.call(result, key)) {
      result = result[key];
    } else {
      return false;
    }
  }
  return true;
};
function getTag(value) {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]';
  }
  return Object.prototype.toString.call(value);
}
function isDate(value) {
  return isObj(value) && getTag(value) == '[object Date]';
}
function nonEmpty(input) {
  if (input == null) {
    return false;
  }
  if (Array.isArray(input) || typeof input === 'string') {
    return !!input.length;
  }
  if (isObj(input)) {
    return !!Object.keys(input).length;
  }
  return typeof input === 'number';
}
function isObj(value) {
  let type = typeof value;
  return !!value && (type == 'object' || type == 'function');
}
function hasOwnProp(obj, prop) {
  return isObj(obj) && isStr(prop) && prop in obj;
}
function existy(x) {
  return x != null;
}
function isStr(something) {
  return typeof something === 'string';
}
function isNum(something) {
  return Number.isFinite(something);
}
function isBool(something) {
  return typeof something === 'boolean';
}
function mergeInternal(i1, i2) {
  if (Array.isArray(i1)) {
    if (nonEmpty(i1)) {
      if (Array.isArray(i2) && nonEmpty(i2)) {
        let temp = [];
        for (let index = 0, len = Math.max(i1.length, i2.length); index < len; index++) {
          if (isObj(i1[index]) && isObj(i2[index])) {
            temp.push(mergeInternal(i1[index], i2[index]));
          } else {
            if (index < i1.length && (i1[index] === i2[index] || i2[index] === undefined)) {
              temp.push(i1[index]);
            } else if (index < i2.length && (i1[index] !== i2[index] || i1[index] === undefined)) {
              temp.push(i2[index]);
            }
          }
        }
        i1 = temp;
      } else {
        return i1;
      }
    } else {
      return nonEmpty(i2) ? i2 : i1;
    }
  } else if (isObj(i1)) {
    if (nonEmpty(i1)) {
      if (Array.isArray(i2)) {
        return nonEmpty(i2) ? i2 : i1;
      }
      if (isObj(i2)) {
        Object.keys(i2).forEach(key => {
          if (hasOwnProp(i1, key)) {
            i1[key] = mergeInternal(i1[key], i2[key]);
          } else {
            i1[key] = i2[key];
          }
        });
        return i1;
      }
      return i1;
    }
    return Array.isArray(i2) || isObj(i2) || nonEmpty(i2) ? i2 : i1;
  } else if (isDate(i1)) {
    return i2;
  } else if (isStr(i1)) {
    if (nonEmpty(i1)) {
      return (Array.isArray(i2) || isObj(i2) || isStr(i2)) && nonEmpty(i2) ? i2 : i1;
    }
    return existy(i2) && !isBool(i2) ? i2 : i1;
  } else if (isNum(i1)) {
    return nonEmpty(i2) ? i2 : i1;
  } else if (isBool(i1)) {
    if (isBool(i2)) {
      return i1 || i2;
    }
    existy(i2) ? i2 : i1;
  } else if (i1 === null) {
    return existy(i2) ? i2 : i1;
  } else {
    return i2;
  }
  //some cases do not return a value.
  return i1;
}
/**
 * Inspired by https://codsen.com/os/object-merge-advanced
 * Merges two objects, recursively combining their properties.
 * If objects have properties with the same key, the values are merged together.
 *
 * @param {Object} sources - The object to merge.
 * @returns {Object} - A new object with merged properties from all sources.
 *

 * @example
 *
 * const parisDetails = {
 *   name: 'Paris',
 *   attractions: ['Eiffel Tower', 'Louvre', 'Champs-Élysées']
 * };
 *
 * const travelExtras = {
 *   activities: ['Guided Tour', 'Seine River Cruise']
 * };
 *
 * const mergedTravel = merge(parisDetails, travelExtras);
 * console.log(mergedTravel);
 * // Result:
 * // {
 * //   name: 'Paris',
 * //   attractions: ['Eiffel Tower', 'Louvre', 'Champs-Élysées'],
 * //   activities: ['Guided Tour', 'Seine River Cruise']
 * // }
 */
const merge = (input1, input2) => {
  if (!input2) return input1;
  if (!isObj(input1) && !isObj(input2)) {
    throw new TypeError('merge: inputs are missing or do not exists');
  }
  // notice we have first argument tracking the current path, which is not
  // exposed to the external API.
  const merged = mergeInternal(input1, input2);
  Object.assign(input1, merged);
  return input1;
};

/**
 * Generated bundle index. Do not edit.
 */

export { cloneDeep, get, has, merge, set };
